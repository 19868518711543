$dark-blue: #36558f;
$dark-green: #4a7c59;
$emigru-orange: #c96c00;
$emigru-orange-hover: #ee9d01;

html {
    overflow-x: hidden;
}

.header {
    background-color: $emigru-orange !important;
}

.header-mobile {
    background-color: $emigru-orange !important;

}

.header-fixed[data-header-scroll="on"] .header {
    background-color: $emigru-orange !important;
}

.btn-orange {
    background: $emigru-orange !important;
    color: white !important;
    font-weight: 700 !important;
    padding-left: 4rem !important;
    padding-right: 4rem !important;
}

.btn-orange:hover {
    background: $emigru-orange-hover !important;
}

.btn-outline-orange {
    background: white !important;
    color: $emigru-orange !important;
    border: 2px solid $emigru-orange !important;
    font-weight: 700 !important;
    padding-left: 4rem !important;
    padding-right: 4rem !important;
}

.btn-outline-orange:hover {
    background: $emigru-orange-hover !important;
    border-color: $emigru-orange-hover !important;
    color: white !important;
}

.btn-flat {
    background-color: purple;
    color: white;
}

.btn-xxl {
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
}

.landing {
    .hero {
        padding: 3rem 0 10rem;

        @media (min-width: 992px) {
            padding: 3rem 0 5rem;
        }
    }

    .bg-full {
        position: relative;
    }

    .bg-full:before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: 100vw;
    }

    .bg-dark-blue:before {
        background: $dark-blue;
    }

    .bg-dark-green:before {
        background: $dark-green;
    }

    .section {
        padding: 5rem 0;

        @media (min-width: 992px) {
            padding: 10rem 0;
        }
    }

    .vertical-center {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .feature-1 {
        .icon {
            position: absolute;
            top: 3rem;
            right: 2rem;
            width: 36px;
            height: auto;

            @media (min-width: 992px) {
                top: 3rem;
                right: 8rem;
                width: 48px;
                height: auto;
            }
        }
    }
    .feature-2 {
        ul {
            columns: 2;
            list-style: none;
            padding-inline-start: 0px;
        }

        ul li {
            padding-left: 30px;
            background: url(/emigru/media/checkmark.svg) left 0px top 3px no-repeat;
            background-size: 18px auto;
        }

        ul li:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
}
